import React, { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  & swiper-slide {
    height: auto;
  }
`;

interface PricePlanSlider {
  children: React.ReactNode;
}

const PricePlanSlider: FC<PricePlanSlider> = ({ children }) => {
  const plansSlider = useRef<any>(null);

  useEffect(() => {
    const swiperContainer = plansSlider.current;

    const params = {
      slidesPerView: 1.2,
      spaceBetween: 16,
      rewind: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      },
    };

    if (swiperContainer) {
      Object.assign(swiperContainer, params);
      swiperContainer.initialize();
    }
  }, [plansSlider]);

  return (
    <Container>
      <swiper-container ref={plansSlider} init={false}>
        {children}
      </swiper-container>
    </Container>
  );
};

export default PricePlanSlider;
