import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';

const Container = styled('div')`
  align-items: center;
  background-color: ${colors.green100};
  border-radius: 16px;
  box-sizing: border-box;
  color: ${colors.black};
  display: flex;
  font-size: 14px;
  justify-content: center;
  height: 28px;
  padding: 0 16px;
`;

interface LabelProps {
  text: string;
}

const Label: FC<LabelProps> = ({ text }) => <Container>{text}</Container>;

export default Label;
