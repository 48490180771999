import React, { FC } from 'react';
import styled from 'styled-components';

import Label from 'src/components/Label';
import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';
import { EuroCircleIcon } from 'src/assets/icons';

const Container = styled.div<{ $main: boolean }>`
  background-color: ${({ $main }) =>
    $main ? `${colors.black}` : `${colors.white}`};
  color: ${({ $main }) => ($main ? `${colors.white}` : `${colors.black}`)};
  padding: 32px 40px 40px;
  height: 100%;

  @media ${device.mobileDOWN} {
    padding: 16px 12px;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;

  @media ${device.desktop} {
    font-size: 22px;
  }
`;

const IntroText = styled.p<{ $main: boolean }>`
  color: ${({ $main }) => ($main ? `${colors.white}` : `${colors.grey600}`)};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 12px;
  text-align: center;
  text-transform: uppercase;
`;

const Price = styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 50px;
  justify-content: center;
  line-height: 1;
  margin: 0;

  @media ${device.desktop} {
    font-size: 65px;
  }
`;

const EuroSymbol = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-right: 8px;

  @media ${device.desktop} {
    font-size: 30px;
  }
`;

const Content = styled.div`
  margin-top: 20px;

  & li {
    font-size: 14px;
  }

  @media ${device.desktop} {
    & ul:has(> :only-child) {
      min-height: 68px; // Temporary: To align CTA buttons, when list has only one child.
    }
  }
`;

const Separator = styled.div`
  height: 23px;
  margin: 0 0 20px;
`;

const SupportText = styled.p<{ $main: boolean }>`
  color: ${({ $main }) => ($main ? `${colors.white}` : `${colors.grey600}`)};
  font-size: 12px;
  margin: 0 0 20px;
  text-align: center;

  @media ${device.desktop} {
    font-size: 14px;
  }
`;

const EuroIcon = styled(EuroCircleIcon)`
  @media ${device.desktop} {
    height: 64px;
  }
`;

interface PricePlanProps {
  title: string;
  labelText?: string;
  introText?: string;
  price?: string;
  supportText?: string;
  children: React.ReactNode;
  main?: boolean;
}

const PricePlan: FC<PricePlanProps> = ({
  title,
  labelText,
  introText,
  price,
  supportText,
  children,
  main = false,
}) => {
  return (
    <Container $main={main}>
      <Header>
        <Title>{title}</Title>
        {labelText && <Label text={labelText} />}
      </Header>
      <IntroText $main={main}>{introText}</IntroText>
      {price && (
        <Price>
          <EuroSymbol>€</EuroSymbol>
          {price}
        </Price>
      )}

      {!price && (
        <Price>
          <EuroIcon />
        </Price>
      )}

      {supportText ? (
        <SupportText $main={main}>{supportText}</SupportText>
      ) : (
        <Separator />
      )}
      <Content>{children}</Content>
    </Container>
  );
};

export default PricePlan;
