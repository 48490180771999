import { useTranslation } from 'react-i18next';

export const getImportChannelsTableData = () => {
  const { t } = useTranslation();

  return [
    {
      channel: t('index_channels_table_suppliers'),
      code: true,
      name: true,
      price: true,
      stock: true,
      deliverydate: true,
      images: false,
      attributes: false,
      orders: false,
      invoices: true,
    },
    {
      channel: t('index_channels_table_ecommerce'),
      code: true,
      name: true,
      price: true,
      stock: true,
      deliverydate: true,
      images: true,
      attributes: true,
      orders: true,
      invoices: true,
    },
    {
      channel: t('index_channels_table_erpcrm'),
      code: true,
      name: true,
      price: true,
      stock: true,
      deliverydate: true,
      images: true,
      attributes: true,
      orders: true,
      invoices: true,
    },
    {
      channel: t('index_channels_table_marketplace'),
      code: false,
      name: false,
      price: false,
      stock: false,
      deliverydate: false,
      images: false,
      attributes: false,
      orders: true,
      invoices: false,
    },
    {
      channel: t('index_channels_table_db'),
      code: true,
      name: true,
      price: false,
      stock: false,
      deliverydate: false,
      images: true,
      attributes: true,
      orders: false,
      invoices: false,
    },
    {
      channel: t('index_channels_table_wholesale'),
      code: false,
      name: false,
      price: false,
      stock: false,
      deliverydate: false,
      images: false,
      attributes: false,
      orders: true,
      invoices: false,
    },
  ];
};
