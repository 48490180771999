import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { device } from 'src/theme/breakpoints';
import { CheckmarkIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';

const UnorderedList = styled.ul<{ $columns: number }>`
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${device.laptopSUP} {
    ${({ $columns }) =>
      $columns &&
      css`
        column-gap: 24px;
        display: grid;
        grid-template-columns: repeat(${$columns}, 1fr);
      `}
  }
`;

const ListItem = styled.li`
  align-items: flex-start;
  display: flex;
  font-size: 16px;
  justify-content: flex-start;
  margin-bottom: 8px;
  position: relative;
`;

const IconWrapper = styled.div`
  height: auto;
  margin: 0 12px 0 0;
  min-width: 14px;
  width: 14px;

  & path {
    stroke: ${colors.green400};
  }
`;

interface ListProps {
  className?: string;
  columns?: number;
  items: string[];
}

const List: FC<ListProps> = ({ className, columns = 1, items }) => (
  <UnorderedList className={className} $columns={columns}>
    {items.map((item, i) => (
      <ListItem key={i}>
        <IconWrapper>
          <CheckmarkIcon />
        </IconWrapper>
        {item}
      </ListItem>
    ))}
  </UnorderedList>
);

export default List;
