import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import { StarIcon } from 'src/assets/icons';

const Stars = styled.div`
  column-gap: 14px;
  display: flex;
`;

const Text = styled.p`
  margin: 24px 0 32px;
`;

const Author = styled.p`
  margin: auto 0 0;
`;

const Wrapper = styled.div`
  border: 1px solid ${colors.grey200};
  padding: 32px;
`;

interface TestimonialProps {
  stars: number;
  text: string | React.ReactNode;
  author: string;
}

const Testimonial: FC<TestimonialProps> = ({ stars, text, author }) => {
  return (
    <Wrapper>
      <Stars>
        {Array.from({ length: stars }, (_, index) => (
          <StarIcon key={index} />
        ))}
      </Stars>
      <Text>{text}</Text>
      <Author>{author}</Author>
    </Wrapper>
  );
};

export default Testimonial;
