import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';

const Container = styled('div')`
  background-color: ${colors.green100};
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.1;
  max-width: 360px;
  padding: 22px 52px 26px 18px;
  position: relative;
  width: 100%;

  &:before {
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-top: 24px solid ${colors.green100};
    bottom: -20px;
    content: '';
    height: 0;
    left: 30px;
    position: absolute;
    width: 0;
  }
`;

interface TooltipProps {
  children: string | React.ReactNode;
}

const Tooltip: FC<TooltipProps> = ({ children }) => (
  <Container>{children}</Container>
);

export default Tooltip;
