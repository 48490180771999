import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  align-items: center;
  font-size: 14px;
  display: flex;
`;

const Icon = styled('div')`
  margin-right: 6px;
  height: 40px;
  text-align: center;
  width: 40px;
`;

interface IconTextProps {
  icon?: React.ReactNode;
  text: string;
}

const IconText: FC<IconTextProps> = ({ icon, text }) => (
  <Container>
    {icon && <Icon>{icon}</Icon>}
    {text}
  </Container>
);

export default IconText;
