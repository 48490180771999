import { useTranslation } from 'react-i18next';

export const getChannelsTableHeaders = () => {
  const { t } = useTranslation();
  return [
    t('index_channels_table_code'),
    t('index_channels_table_name'),
    t('index_channels_table_price'),
    t('index_channels_table_stock'),
    t('index_channels_table_date'),
    t('index_channels_table_images'),
    t('index_channels_table_attributes'),
    t('index_channels_table_orders'),
    t('index_channels_table_invoices'),
  ];
};
