import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Grid from 'src/components/Grid';
import { device } from 'src/theme/breakpoints';
import Testimonial from 'src/components/Testimonial';
import Counter from 'src/components/Counter';
import Link, { LinkVariant } from 'src/components/Link';
import { TrustPilotIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';

const Aside = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media ${device.mobileDOWN} {
    align-items: center;
    grid-column: 1/2;
    grid-row: 2/3;

    & > a {
      align-self: center;
    }
  }
`;

const AsideTop = styled.div`
  column-gap: 48px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
`;

const AsideBottom = styled.div`
  margin-top: 32px;
  font-size: 14px;

  @media ${device.mobileDOWN} {
    display: none;
  }

  @media ${device.laptopSUP} {
    margin-top: 80px;
  }
`;

const StyledLogo = styled(TrustPilotIcon)`
  @media ${device.mobileDOWN} {
    height: auto;
    margin-bottom: 24px;
    width: 160px;
  }
`;

const Footer = styled(Grid)`
  border-top: 1px solid ${colors.grey200};
  margin-top: 40px;
  padding-top: 32px;

  @media ${device.mobileDOWN} {
    display: none;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const FooterTitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;

  @media ${device.desktop} {
    font-size: 23px;
  }
`;

const StyledGrid = styled(Grid)`
  @media ${device.mobileDOWN} {
    & > :nth-child(3),
    & > :last-child {
      display: none;
    }
  }
`;

const Testimonials: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledGrid columns={4}>
        <Aside>
          <AsideTop>
            <Counter title="4.7" text={t('common_rating')} />
          </AsideTop>
          <Link
            variant={LinkVariant.DELTA}
            url="https://www.trustpilot.com/review/omniget.com"
          >
            <Trans i18nKey="common_read_more_reviews" />
          </Link>
          <AsideBottom>
            <p>
              <Trans i18nKey="common_powered_by" />
            </p>
            <StyledLogo />
          </AsideBottom>
        </Aside>
        <Testimonial
          stars={5}
          text={<Trans i18nKey="index_testimonial_1" />}
          author="- www.printera.lt"
        />
        <Testimonial
          stars={5}
          text={<Trans i18nKey="index_testimonial_2" />}
          author="- www.pigu.lt"
        />
        <Testimonial
          stars={5}
          text={<Trans i18nKey="index_testimonial_3" />}
          author="- www.x-kom.pl"
        />
      </StyledGrid>
      <Footer columns={4}>
        <FooterTitle>
          OmniGet <br />
          <Trans i18nKey="common_by_numbers" />
        </FooterTitle>
        <Counter title="250+" text={<Trans i18nKey="index_counter_1" />} />
        <Counter title="3 mln. +" text={<Trans i18nKey="index_counter_2" />} />
        <Counter title="10 min." text={<Trans i18nKey="index_counter_3" />} />
      </Footer>
    </>
  );
};

export default Testimonials;
