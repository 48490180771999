import React, { FC } from 'react';
import styled from 'styled-components';

const Title = styled('div')`
  font-size: 42px;
  font-weight: 500;
  line-height: 1;
`;

const Text = styled('p')`
  font-size: 14px;
  margin: 12px 0 0;
`;

interface CounterProps {
  title: string;
  text: string | React.ReactNode;
}

const Counter: FC<CounterProps> = ({ title, text }) => (
  <div>
    <Title>{title}</Title>
    <Text>{text}</Text>
  </div>
);

export default Counter;
