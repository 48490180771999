import { useTranslation } from 'react-i18next';

export const getBenefitsData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('index_benefits_1_title'),
      text: t('index_benefits_1_text'),
    },
    {
      title: t('index_benefits_2_title'),
      text: t('index_benefits_2_text'),
    },
    {
      title: t('index_benefits_3_title'),
      text: t('index_benefits_3_text'),
    },
    {
      title: t('index_benefits_4_title'),
      text: t('index_benefits_4_text'),
    },
    {
      title: t('index_benefits_5_title'),
      text: t('index_benefits_5_text'),
    },
    {
      title: t('index_benefits_6_title'),
      text: t('index_benefits_6_text'),
    },
    {
      title: t('index_benefits_7_title'),
      text: t('index_benefits_7_text'),
    },
    {
      title: t('index_benefits_8_title'),
      text: t('index_benefits_8_text'),
    },
    {
      title: t('index_benefits_9_title'),
      text: t('index_benefits_9_text'),
    },
  ];
};
