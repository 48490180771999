import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from 'src/components/SEO';
import Layout from 'src/components/Layout';
import { HeadingScope } from 'src/components/Heading';
import { device } from 'src/theme/breakpoints';
import { LINKS } from 'src/utils/constants';
import Link, { LinkVariant } from 'src/components/Link';
import Content from 'src/components/Content';
import SectionTitle from 'src/components/SectionTitle';
import Grid from 'src/components/Grid';
import { ConnectionIcon, EuroIcon, NoCardIcon } from 'src/assets/icons';
import {
  AlsoIcon,
  PiguIcon,
  KainaIcon,
  EbayIcon,
  WooIcon,
  NccIcon,
  ShopifyIcon,
  PrestaIcon,
} from 'src/assets/icons/platforms';
import {
  ActionIcon,
  B2BIcon,
  BestBuyIcon,
  KomputronikIcon,
  MatterhornIcon,
  MCIcon,
  SportsmanIcon,
  VidaIcon,
  WholesalerIcon,
} from 'src/assets/icons/clients';
import BackgroundSection from 'src/components/BackgroundSection';
import List from 'src/components/List';
import Toggle, { ToggleVariant } from 'src/components/Toggle';
import PricePlan from 'src/components/PricePlan';
import IconText from 'src/components/IconText';
import Steps from 'src/components/Steps';
import Tooltip from 'src/components/Tooltip';
import Testimonials from 'src/components/Testimonials';
import Button, { ButtonVariant } from 'src/components/Button';
import Banner from 'components/Banner';
import Platforms from 'components/Platforms';
import { useModal } from 'components/Modal/ModalContext';
import ContactForm from 'components/ContactForm';
import { colors } from 'src/theme/colors';
import InfoCard from 'components/InfoCard';
import ImageRow from 'components/ImageRow';
import Table from 'components/Table';
import PricePlanSlider from 'components/PricePlan/PricePlanSlider';
import Accordion from 'components/Accordion';
import { AccordionVariant } from 'components/Accordion/constants';
import { getChannelsTableHeaders } from 'src/data/tables/channels-table-headers';
import { getFeaturesData } from 'src/data/index/features-data';
import { getBenefitsData } from 'src/data/index/benefits-data';
import { getImportChannelsTableData } from 'src/data/tables/import-channels-table-data';
import { getExportChannelsTableData } from 'src/data/tables/export-channels-table-data';

const HeroButtonWrapper = styled.div`
  @media ${device.mobileDOWN} {
    align-self: center;
    text-align: center;
  }
`;

const HeroButtonLabel = styled.p`
  font-size: 14px;
  margin: 12px 0 0;

  @media ${device.tabletUP} {
    padding-left: 20px;
  }
`;

const PlatformsSectionWrapper = styled.div`
  background-color: ${colors.black};
  padding-bottom: 80px;

  @media ${device.tabletDOWN} {
    padding: 24px 0;
  }
`;

const PlatformsSectionTitle = styled.p`
  color: ${colors.grey600};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 32px;
  text-transform: uppercase;

  @media ${device.mobileDOWN} {
    font-size: 12px;
    margin: 0 0 16px;
  }
`;

const PlatformsSectionLogosWrapper = styled.div`
  align-items: center;
  column-gap: 40px;
  display: flex;
`;

const PlatformsSectionButton = styled(Button)`
  color: ${colors.white};
  flex: 1 0 auto;

  @media ${device.mobileDOWN} {
    display: none;
  }
`;

const CompareSectionWrapper = styled.div`
  background-color: ${colors.black};
  color: ${colors.grey200};
  padding: 64px;

  @media ${device.tabletDOWN} {
    padding: 24px 16px;
  }
`;

const CompareSection = styled.div`
  align-items: flex-start;
  display: flex;
  column-gap: 40px;

  @media ${device.desktop} {
    column-gap: 80px;
  }

  @media ${device.mobileDOWN} {
    flex-direction: column;
  }
`;

const CompareSectionText = styled.div`
  @media ${device.tablet} {
    flex: 0 1 320px;
    max-width: 320px;
  }

  @media ${device.laptopSUP} {
    flex: 0 1 540px;
    max-width: 540px;
  }

  & div {
    color: ${colors.green400};
    font-weight: 700;
    margin-top: 32px;
  }

  & p {
    margin: 0;
  }
`;

const CompareSectionImage = styled.div`
  flex: 0 1 344px;
  width: 100%;

  & > div {
    height: 344px;
  }

  @media ${device.laptopUP} {
    margin-top: -42px;
    flex: 0 1 560px;

    & > div {
      height: 560px;
    }
  }
`;

const FeaturesSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 88px;

  @media ${device.mobileDOWN} {
    row-gap: 32px;
  }
`;

const PricePlanButtonWrapper = styled.div`
  margin: 24px 0;

  & button {
    width: 100%;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 30px;
  margin-left: 20%;
`;

const StepsButton = styled(Link)`
  margin: 80px auto 0;
  width: 268px;
`;

const HowToStart = styled.div`
  @media ${device.tabletDOWN} {
    display: none;
  }
`;

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const featuresData = getFeaturesData();
  const benefitsData = getBenefitsData();
  const channelsTableHeaders = getChannelsTableHeaders();
  const importChannelsTabledata = getImportChannelsTableData();
  const exportChannelsTabledata = getExportChannelsTableData();

  return (
    <Layout>
      <Banner
        image={
          <StaticImage
            src="../assets/images/omniget-main-en.jpg"
            alt="OmniGet."
            layout="fullWidth"
          />
        }
        title={t('index_main_heading')}
        list={[t('index_main_list_0'), t('index_main_list_1')]}
      >
        <HeroButtonWrapper>
          <Link url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
            <Trans i18nKey="common_try_free_of_charge" />
          </Link>
          <HeroButtonLabel>
            <Trans i18nKey="common_no_card_required" />
          </HeroButtonLabel>
        </HeroButtonWrapper>
      </Banner>

      <PlatformsSectionWrapper>
        <Content>
          <PlatformsSectionTitle>
            <Trans i18nKey="index_main_channels" />
          </PlatformsSectionTitle>
          <PlatformsSectionLogosWrapper>
            <Platforms
              id="platforms"
              logos={[
                <WooIcon key="f" />,
                <PiguIcon key="pigu" />,
                <AlsoIcon key="also" />,
                <KainaIcon key="kaina" />,
                <PrestaIcon key="presta" />,
                <NccIcon key="ncc" />,
                <EbayIcon key="ebay" />,
                <ShopifyIcon key="shopify" />,
              ]}
            />
            <PlatformsSectionButton
              variant={ButtonVariant.CHARLIE}
              onClick={openModal}
            >
              <Trans i18nKey="common_request_for_more" />
            </PlatformsSectionButton>
          </PlatformsSectionLogosWrapper>
        </Content>
      </PlatformsSectionWrapper>

      <HeadingScope>
        <Content>
          <SectionTitle subtitle={t('index_benefits_subtitle')}>
            <Trans i18nKey="index_benefits_title" />
          </SectionTitle>
          <Grid columns={3} showMore>
            {benefitsData.map((benefit, index) => (
              <InfoCard
                key={benefit.title}
                number={`${index + 1}`}
                text={benefit.text}
                title={benefit.title}
              />
            ))}
          </Grid>
        </Content>

        <BackgroundSection>
          <SectionTitle
            subtitle={t('index_compare_subtitle')}
            introText={t('index_compare_text')}
          >
            <Trans i18nKey="index_compare_title" />
          </SectionTitle>
          <CompareSectionWrapper>
            <Toggle
              isOnDark
              variant={ToggleVariant.BRAVO}
              content={[
                {
                  title: t('index_compare_tab_1_title'),
                  content: (
                    <CompareSection>
                      <CompareSectionText>
                        <p>
                          <Trans i18nKey="index_compare_tab_1_text_0" />
                        </p>
                        <div>
                          <Trans i18nKey="index_compare_tab_1_text_1" />
                        </div>
                        <p>
                          <Trans i18nKey="index_compare_tab_1_text_2" />
                        </p>
                      </CompareSectionText>
                      <CompareSectionImage>
                        <StaticImage
                          src="../assets/images/compare-hybrid-en.png"
                          alt={t('index_compare_tab_1_title')}
                          layout="fullWidth"
                          objectFit="contain"
                        />
                      </CompareSectionImage>
                    </CompareSection>
                  ),
                },
                {
                  title: t('index_compare_tab_2_title'),
                  content: (
                    <CompareSection>
                      <CompareSectionText>
                        <p>
                          <Trans i18nKey="index_compare_tab_2_text_0" />
                        </p>
                        <div>
                          <Trans i18nKey="index_compare_tab_2_text_1" />
                        </div>
                        <p>
                          <Trans i18nKey="index_compare_tab_2_text_2" />
                        </p>
                      </CompareSectionText>
                      <CompareSectionImage>
                        <StaticImage
                          src="../assets/images/compare-regular-en.png"
                          alt={t('index_compare_tab_2_title')}
                          layout="fullWidth"
                          objectFit="contain"
                        />
                      </CompareSectionImage>
                    </CompareSection>
                  ),
                },
              ]}
            />
          </CompareSectionWrapper>
        </BackgroundSection>

        <Content>
          <SectionTitle subtitle={t('index_features_subtitle')} centered>
            <Trans i18nKey="index_features_title" />
          </SectionTitle>

          <FeaturesSectionWrapper>
            {featuresData.map(feature => (
              <ImageRow
                heading={feature.heading}
                image={feature.image}
                key={feature.heading}
                imageOnLeft={feature.imageOnLeft}
                linkText={feature.linkText}
                linkUrl={feature.linkUrl}
              >
                {feature.children}
              </ImageRow>
            ))}
          </FeaturesSectionWrapper>

          <SectionTitle subtitle={t('index_channels_subtitle')} centered>
            <Trans i18nKey="index_channels_title" />
          </SectionTitle>
          <Toggle
            variant={ToggleVariant.BRAVO}
            content={[
              {
                title: t('common_product_information_import'),
                content: (
                  <Table
                    headers={channelsTableHeaders}
                    rows={importChannelsTabledata}
                    caption={`${t('index_channels_table_caption_import')}:`}
                  />
                ),
              },
              {
                title: t('common_product_information_export'),
                content: (
                  <Table
                    headers={channelsTableHeaders}
                    rows={exportChannelsTabledata}
                    caption={`${t('index_channels_table_caption_export')}:`}
                  />
                ),
              },
            ]}
          />
        </Content>

        <div id="pricing">
          <BackgroundSection>
            <SectionTitle subtitle={t('index_plans_subtitle')} centered>
              <Trans i18nKey="index_plans"></Trans>
            </SectionTitle>
            <Toggle
              variant={ToggleVariant.ALFA}
              content={[
                {
                  title: t('common_paid_a_month'),
                  content: (
                    <PricePlanSlider>
                      <swiper-slide>
                        <PricePlan
                          title={t('common_basic')}
                          labelText={t('common_free_forever')}
                          introText={t('common_useful_plan')}
                          price="0.00"
                        >
                          <List
                            items={[
                              t('common_up_to_channels', { number: 2 }),
                              t('common_up_to_products', { number: '1 000' }),
                            ]}
                          />
                          <PricePlanButtonWrapper>
                            <Button
                              variant={ButtonVariant.DELTA}
                              onClick={openModal}
                            >
                              <Trans i18nKey="common_get_started" />
                            </Button>
                          </PricePlanButtonWrapper>
                          <IconText
                            icon={<NoCardIcon />}
                            text={t('commmon_no_card_required')}
                          />
                          <IconText
                            icon={<ConnectionIcon />}
                            text={t('common_help_desk_standard')}
                          />
                        </PricePlan>
                      </swiper-slide>
                      <swiper-slide>
                        <PricePlan
                          title={t('common_standard')}
                          labelText="-45%"
                          introText={t('common_most_popular_plan')}
                          price="49.00"
                          main
                          supportText={t('common_per_month')}
                        >
                          <List
                            items={[
                              t('common_up_to_channels', { number: 5 }),
                              t('common_up_to_products', {
                                number: '10 000',
                              }),
                            ]}
                          />
                          <PricePlanButtonWrapper>
                            <Button
                              variant={ButtonVariant.ALFA}
                              onClick={openModal}
                            >
                              <Trans i18nKey="common_get_started" />
                            </Button>
                          </PricePlanButtonWrapper>
                          <IconText
                            icon={<EuroIcon />}
                            text={t('common_30_days_guarantee')}
                          />
                          <IconText
                            icon={<ConnectionIcon />}
                            text={t('common_help_desk_priority')}
                          />
                        </PricePlan>
                      </swiper-slide>
                      <swiper-slide>
                        <PricePlan
                          title={t('common_custom')}
                          introText={t('common_best_for_enterprises')}
                          supportText={t('common_price_is_subject')}
                        >
                          <List items={[t('common_adjusted_integrations')]} />
                          <PricePlanButtonWrapper>
                            <Button
                              variant={ButtonVariant.DELTA}
                              onClick={openModal}
                            >
                              <Trans i18nKey="common_get_quote" />
                            </Button>
                          </PricePlanButtonWrapper>
                          <IconText
                            icon={<EuroIcon />}
                            text={t('common_30_days_guarantee')}
                          />
                          <IconText
                            icon={<ConnectionIcon />}
                            text={t('common_help_desk_priority')}
                          />
                        </PricePlan>
                      </swiper-slide>
                    </PricePlanSlider>
                  ),
                },
                {
                  title: t('common_paid_a_quarter'),
                  content: (
                    <PricePlanSlider>
                      <swiper-slide>
                        <PricePlan
                          title={t('common_basic')}
                          labelText={t('common_free_forever')}
                          introText={t('common_useful_plan')}
                          price="0.00"
                        >
                          <List
                            items={[
                              t('common_up_to_channels', { number: 2 }),
                              t('common_up_to_products', { number: '1 000' }),
                            ]}
                          />
                          <PricePlanButtonWrapper>
                            <Button
                              variant={ButtonVariant.DELTA}
                              onClick={openModal}
                            >
                              <Trans i18nKey="common_get_started" />
                            </Button>
                          </PricePlanButtonWrapper>
                          <IconText
                            icon={<NoCardIcon />}
                            text={t('commmon_no_card_required')}
                          />
                          <IconText
                            icon={<ConnectionIcon />}
                            text={t('common_help_desk_standard')}
                          />
                        </PricePlan>
                      </swiper-slide>
                      <swiper-slide>
                        <PricePlan
                          title={t('common_standard')}
                          labelText="-45%"
                          introText={t('common_most_popular_plan')}
                          price="44.50"
                          main
                          supportText={t('common_per_month')}
                        >
                          <List
                            items={[
                              t('common_up_to_channels', { number: 5 }),
                              t('common_up_to_products', {
                                number: '10 000',
                              }),
                            ]}
                          />
                          <PricePlanButtonWrapper>
                            <Button
                              variant={ButtonVariant.ALFA}
                              onClick={openModal}
                            >
                              <Trans i18nKey="common_get_started" />
                            </Button>
                          </PricePlanButtonWrapper>
                          <IconText
                            icon={<EuroIcon />}
                            text={t('common_30_days_guarantee')}
                          />
                          <IconText
                            icon={<ConnectionIcon />}
                            text={t('common_help_desk_priority')}
                          />
                        </PricePlan>
                      </swiper-slide>
                      <swiper-slide>
                        <PricePlan
                          title={t('common_custom')}
                          introText={t('common_best_for_enterprises')}
                          supportText={t('common_price_is_subject')}
                        >
                          <List items={[t('common_adjusted_integrations')]} />
                          <PricePlanButtonWrapper>
                            <Button
                              variant={ButtonVariant.DELTA}
                              onClick={openModal}
                            >
                              <Trans i18nKey="common_get_quote" />
                            </Button>
                          </PricePlanButtonWrapper>
                          <IconText
                            icon={<EuroIcon />}
                            text={t('common_30_days_guarantee')}
                          />
                          <IconText
                            icon={<ConnectionIcon />}
                            text={t('common_help_desk_priority')}
                          />
                        </PricePlan>
                      </swiper-slide>
                    </PricePlanSlider>
                  ),
                },
              ]}
            />
          </BackgroundSection>
        </div>

        <HowToStart id="how-to-start">
          <Content>
            <SectionTitle subtitle={t('index_how_to_start_subtitle')}>
              <Trans i18nKey="index_how_to_start"></Trans>
            </SectionTitle>
            <TooltipWrapper>
              <Tooltip>
                <Trans i18nKey="index_how_to_start_tooltip"></Trans>
              </Tooltip>
            </TooltipWrapper>
            <Steps
              steps={[
                {
                  number: '1',
                  title: t('index_how_to_start_1_title'),
                  text: t('index_how_to_start_1_text'),
                },
                {
                  number: '2',
                  title: t('index_how_to_start_2_title'),
                  text: t('index_how_to_start_2_text'),
                },
                {
                  number: '3',
                  title: t('index_how_to_start_3_title'),
                  text: t('index_how_to_start_3_text'),
                },
                {
                  number: '4',
                  title: t('index_how_to_start_4_title'),
                  text: t('index_how_to_start_4_text'),
                },
              ]}
            />
            <StepsButton url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
              <Trans i18nKey="common_sign_up" />
            </StepsButton>
          </Content>
        </HowToStart>

        <BackgroundSection>
          <SectionTitle subtitle={t('index_testimonials_subtitle')} centered>
            <Trans i18nKey="index_testimonials_title" />
          </SectionTitle>
          <Testimonials />
        </BackgroundSection>

        <Content>
          <SectionTitle
            subtitle={t('common_clients')}
            centered
            introText={t('common_they_trust_text')}
          >
            <Trans i18nKey="common_they_trust" />
          </SectionTitle>
          <Platforms
            id="clients"
            maxSlidesLaptop={10}
            maxSlidesDesktop={10}
            logos={[
              <ActionIcon key="action" />,
              <AlsoIcon key="also" />,
              <B2BIcon key="b2b" />,
              <BestBuyIcon key="bestbuy" />,
              <KomputronikIcon key="kompu" />,
              <MatterhornIcon key="matt" />,
              <MCIcon key="mc" />,
              <SportsmanIcon key="sports" />,
              <VidaIcon key="vida" />,
              <WholesalerIcon key="whole" />,
            ]}
          />
        </Content>

        <div id="faq">
          <BackgroundSection isDarker>
            <SectionTitle centered subtitle={t('common_additional_info')}>
              <Trans i18nKey="index_faq"></Trans>
            </SectionTitle>
            <Accordion
              variant={AccordionVariant.ALFA}
              items={[
                {
                  title: t('faq_1_title'),
                  content: t('faq_1_content'),
                },
                {
                  title: t('faq_2_title'),
                  content: t('faq_2_content'),
                },
                {
                  title: t('faq_3_title'),
                  content: t('faq_3_content'),
                },
                {
                  title: t('faq_4_title'),
                  content: (
                    <>
                      <Trans i18nKey="faq_4_content_0" />
                      <Button
                        variant={ButtonVariant.CHARLIE}
                        onClick={openModal}
                      >
                        <Trans i18nKey="faq_4_content_1" />
                      </Button>
                      <Trans i18nKey="faq_4_content_2" />
                    </>
                  ),
                },
                {
                  title: t('faq_5_title'),
                  content: t('faq_5_content'),
                },
                {
                  title: t('faq_6_title'),
                  content: t('faq_6_content'),
                },
                {
                  title: t('faq_7_title'),
                  content: t('faq_7_content'),
                },
                {
                  title: t('faq_8_title'),
                  content: t('faq_8_content'),
                },
              ]}
            />
          </BackgroundSection>
        </div>
      </HeadingScope>
      <ContactForm />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Product information share & management tool | OmniGet"
    description="Enhance efficiency with OmniGet, the ultimate tool for sharing and managing product information. Simplify workflows and ensure data accuracy with our intuitive platform."
  />
);
