import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

import { colors } from 'src/theme/colors';
import { LinkVariant } from './constants';
import { device } from 'src/theme/breakpoints';
import { LinkCaretIcon } from 'src/assets/icons';

export const linkStyles = css<Pick<LinkProps, 'variant' | '$withCaret'>>`
  ${({ variant, $withCaret }) =>
    variant === LinkVariant.ALFA &&
    css`
      color: ${colors.purple700};
      text-decoration: ${$withCaret ? 'none' : 'underline'};

      &:after {
        background-color: ${colors.purple700};
      }
    `}

  ${({ variant }) =>
    variant === LinkVariant.BRAVO &&
    css`
      align-items: center;
      background-color: ${colors.green400};
      border: none;
      border-radius: 32px;
      box-sizing: border-box;
      color: ${colors.white};
      cursor: pointer;
      display: flex;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      justify-content: center;
      height: 50px;
      padding: 0 42px;

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
    `}

    ${({ variant }) =>
    variant === LinkVariant.CHARLIE &&
    css`
      color: ${colors.green400};
      text-decoration: underline;
    `}

    ${({ variant }) =>
    variant === LinkVariant.DELTA &&
    css`
      align-items: center;
      align-self: flex-start;
      background-color: transparent;
      border: 1px solid ${colors.green400};
      border-radius: 32px;
      color: ${colors.green400};
      display: flex;
      font-size: 16px;
      font-weight: 500;
      justify-content: center;
      height: 50px;
      padding: 0 40px;
    `}
`;

const InternalLink = styled(GatsbyLink)`
  ${linkStyles};
`;

const ExternalLink = styled('a')`
  ${linkStyles};
`;

const StyledIcon = styled(LinkCaretIcon)`
  margin-left: 12px;

  @media ${device.laptop} {
    transform: scale(0.8);
  }
`;

interface LinkProps {
  children: string | React.ReactNode;
  className?: string;
  url: string;
  variant: LinkVariant;
  $withCaret?: boolean;
  openInNewTab?: boolean;
}

const Link: FC<LinkProps> = ({
  children,
  className,
  url,
  variant,
  $withCaret,
  openInNewTab = false,
}) => {
  const internal = /^\/(?!\/)/.test(url);

  if (internal) {
    return (
      <InternalLink
        to={url}
        className={className}
        variant={variant}
        $withCaret={$withCaret}
      >
        {children}
        {$withCaret && <StyledIcon />}
      </InternalLink>
    );
  }

  return (
    <ExternalLink
      href={url}
      target={openInNewTab ? '_blank' : '_self'}
      rel="noopener noreferrer"
      className={className}
      variant={variant}
      $withCaret={$withCaret}
    >
      {children}
      {$withCaret && <StyledIcon />}
    </ExternalLink>
  );
};

export default Link;
