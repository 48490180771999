import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors } from 'src/theme/colors';
import { CrossIcon } from 'src/assets/icons';
import { StyledCheckmarkIcon } from 'components/ComparisonTable/ComparisonTable';
import { device } from 'src/theme/breakpoints';

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
`;

const StyledTable = styled.table`
  border: 1px solid ${colors.grey200};
  border-left: 0;
  min-width: 600px;
  width: 100%;
`;

const Caption = styled.caption`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
  text-align: left;
`;

const Head = styled.thead`
  & th:first-child {
    background-color: ${colors.white};
    border-left: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};

    @media ${device.mobileDOWN} {
      left: 0;
      position: sticky;
      z-index: 1;
    }
  }

  & th {
    background-color: ${colors.grey100};
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    max-width: 100px;
    padding: 24px 8px;
    text-align: left;
  }
`;

const Body = styled.tbody`
  & th,
  & td {
    padding: 24px 8px;
    border-top: 1px solid ${colors.grey200};
  }

  & th {
    background-color: ${colors.white};
    border-left: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    font-size: 14px;
    text-align: left;

    @media ${device.mobileDOWN} {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }

  & td {
    background-color: ${colors.grey100};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0.5;
  width: 10px;
`;

interface TableRow {
  channel: string;
  [key: string]: boolean | string;
}

interface TableProps {
  caption: string;
  captionVisible?: boolean;
  headers: string[];
  rows: TableRow[];
}

const Table: React.FC<TableProps> = ({
  caption,
  captionVisible,
  headers,
  rows,
}) => {
  const { t } = useTranslation();
  return (
    <ScrollContainer>
      <StyledTable cellSpacing="0" aria-label={caption}>
        {captionVisible && <Caption>{caption}</Caption>}
        <Head>
          <tr>
            <th></th>
            {headers.map((header, index) => (
              <th key={index} scope="col">
                {header}
              </th>
            ))}
          </tr>
        </Head>
        <Body>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <th scope="row">{row.channel}</th>
              {headers.map((header, colIndex) => {
                const cellKey = header.toLowerCase().replace(/\s+/g, '');
                return (
                  <td key={colIndex}>
                    {typeof row[cellKey] === 'boolean' ? (
                      row[cellKey] ? (
                        <StyledCheckmarkIcon
                          aria-label={t('common_included')}
                        />
                      ) : (
                        <StyledCrossIcon aria-label={t('common_excluded')} />
                      )
                    ) : (
                      row[cellKey]
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </Body>
      </StyledTable>
    </ScrollContainer>
  );
};

export default Table;
