import React, { FC } from 'react';
import styled from 'styled-components';

import { device } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';

const Wrapper = styled('div')`
  align-items: start;
  column-gap: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;

  &:before {
    background-color: ${colors.grey200};
    content: '';
    height: 1px;
    position: absolute;
    top: 30px;
    left: 0;
    width: 90%;
    z-index: 0;
  }

  @media ${device.desktop} {
    column-gap: 54px;
  }
`;

const Step = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 1;
`;

const StepNumber = styled('div')`
  align-items: center;
  background-color: ${colors.green400};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  height: 48px;
  font-size: 18px;
  width: 48px;

  @media ${device.desktop} {
    height: 60px;
    font-size: 21px;
    width: 60px;
  }
`;

const StepTitle = styled('p')`
  font-size: 16px;
  font-weight: 500;
  margin: 32px 0 18px;

  @media ${device.desktop} {
    font-size: 23px;
    margin: 42px 0 24px;
  }
`;

const StepText = styled('p')`
  margin: 0;
`;

interface StepProps {
  number: string;
  text?: string;
  title: string;
}

interface StepsProps {
  steps: StepProps[];
}

const Steps: FC<StepsProps> = ({ steps }) => (
  <Wrapper>
    {steps.map((step, i) => (
      <Step key={i}>
        <StepNumber>{step.number}</StepNumber>
        <StepTitle>{step.title}</StepTitle>
        {step.text && <StepText>{step.text}</StepText>}
      </Step>
    ))}
  </Wrapper>
);

export default Steps;
